<!--
 * @Author: your name
 * @Date: 2022-03-11 13:19:36
 * @LastEditTime: 2022-03-15 16:28:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson24\5_RocketReviewGame.vue
-->
<template>
  <div class="game-container">
    <RocketReviewGame :bcgImg="bcgImg" :durationArr="durationArr" :audioUrl="audioUrl" :trigger="`button`"/>
  </div>
</template>

<script>
import RocketReviewGame from "@/components/Course/GamePage/RocketReviewGame";
export default {
  data() {
    return {
      bcgImg: require("@/assets/img/16-GAMES/G09-submarine/submarine-background-lesson-16.svg"),
      durationArr: [
        0.945,
        0.865,
        0.775,
        0.695,
        0.615,
        0.535,
        0.455,
        0.37,
        0.29,
        0.205,
        0.125,
        0.051
      ],
      audioUrl: require("@/assets/audio/chapter_1/lesson_8/submarine-propeller.mp3")
    };
  },
  components: {
    RocketReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
