<template>
  <div class="game-container">
    <BingoGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      @changeStatus="changeStatus"
      :lessonType="1"
      :bingoIndexList="[3, 5, 4]"
      :totalStars="totalStars"
    />
  </div>
</template>

<script>
import BingoGame from "@/components/Course/GamePage/BingoGame";
export default {
  data() {
    return {
      totalStars: 5,
      lesson: 16,
      questionInfoList: [
        {
          id: 3,
          pinyin: "Zhōngguó rén",
          hanzi: "中国人",
        },

        {
          id: 1,
          pinyin: "Měiguó rén",
          hanzi: "美国人",
        },

        {
          id: 2,
          pinyin: "shí suì",
          hanzi: "十岁",
        },

        {
          id: 4,
          pinyin: "liù suì",
          hanzi: "六岁",
        },
        {
          id: 5,
          pinyin: " Jiānádà rén",
          hanzi: "加拿大人",
        },
      ],
      nameString: ["leftList", "MiddleList", "rightList"],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/2.svg"),
          },
          {
            id: 6,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/9.svg"),
          },
          {
            id: 7,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/6.svg"),
          },
        ],
        MiddleList: [
          {
            id: 9,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/8.svg"),
          },
          {
            id: 2,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/4.svg"),
          },
          {
            id: 8,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/7.svg"),
          },
        ],
        rightList: [
          {
            id: 3,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/1.svg"),
          },
          {
            id: 4,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/5.svg"),
          },

          {
            id: 5,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/chapter-03/3.svg"),
          },
        ],
      },
    };
  },
  components: {
    BingoGame,
  },
  methods: {
    changeStatus(item) {
      // console.log(item);
      for (let i = 0; i <= 2; i++) {
        this.asideElementInfo[this.nameString[i]].forEach((ele) => {
          if (ele.id === item.id) {
            ele.done = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
