<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [
        {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-19-zhongguoren.svg"),
        },
        {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-17-ta-she.svg"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-17-ta-he.svg"),
        },
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-17-jiao.svg"),
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-21-sui.svg"),
        },
        {
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-17-shei.svg"),
        }
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "谁",
            pinyin: "shéi",
            sound: require("@/assets/audio/L1/5-Words/shei2.mp3"),
          },
          {
            id: 2,
            element: "他",
            pinyin: "tā",
            sound: require("@/assets/audio/L1/5-Words/ta1..mp3"),
          },
          {
            id: 3,
            element: "她",
            pinyin: "tā",
            sound: require("@/assets/audio/L1/5-Words/ta1.mp3"),
          },
        ],
        rightList: [
          {
            id: 4,
            element: "中国人",
            pinyin: "Zhōngguó rén",
            sound: require("@/assets/audio/L1/5-Words/zhongguoren.mp3"),
          },
          {
            id: 5,
            element: "岁",
            pinyin: "suì",
            sound: require("@/assets/audio/L1/5-Words/sui4.mp3"),
          },
          {
            id: 6,
            element: "叫",
            pinyin: "jiào",
            sound: require("@/assets/audio/L1/5-Words/jiao4.mp3"),
          },
        ],
      },
    };
  },
  components: {
    FindElementByPicReviewGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;

  ::v-deep .audio-area .audio-player-container img {
    width: 37px;
    height: 37px;
  }

  ::v-deep .word-area {
    width: 100%;
    // max-width: 90px;
  }
  ::v-deep .item-content {
    width: 72%;
  }
}
</style>
