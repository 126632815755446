<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "听",
          isUse: false,
          content: [
            {
              pinyin:
                "nénggòu",
              hanzi: "能够",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin:
                "lǐjiě",
              hanzi: "理解",
              symbol: "",
              group: 0,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin:
                "tīng dào",
              hanzi: "听到",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin:
                "de",
              hanzi: "的",
              symbol: "",
              group: 0,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin:
                "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin:
                "cíhuì,",
              hanzi: "词汇",
              symbol: "",
              group: 0,
              index: 5,
              symbolType: 0,
            },
            {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 6,
                symbolType: 1,
              },
              {
              pinyin:
                "duǎnyǔ",
              hanzi: "短语",
              symbol: "",
              group: 0,
              index: 7,
              symbolType: 0,
            },
            {
              pinyin:
                "hé",
              hanzi: "和",
              symbol: "",
              group: 0,
              index: 8,
              symbolType: 0,
            },
            {
              pinyin:
                "duǎn",
              hanzi: "短",
              symbol: "",
              group: 0,
              index: 9,
              symbolType: 0,
            },
            {
              pinyin:
                "jù",
              hanzi: "句",
              symbol: "",
              group: 0,
              index: 10,
              symbolType: 0,
            },
          ],
        },
        {
          title: "说",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "zài",
                hanzi: "在",
                symbol: "",
                group: 1,
                line: 0,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "yóuxì",
                hanzi: "游戏",
                symbol: "",
                group: 1,
                line: 0,
                index: 1,
                symbolType: 0,
              },
              {
                pinyin: "hé",
                hanzi: "和",
                symbol: "",
                group: 1,
                line: 0,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "qíngjǐng",
                hanzi: "情景",
                symbol: "",
                group: 1,
                line: 0,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "yùnyòng",
                hanzi: "运用",
                symbol: "",
                group: 1,
                line: 0,
                index: 4,
                symbolType: 0,
              },
            {
              pinyin:
                "suǒ",
              hanzi: "所",
              symbol: "",
              group: 0,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin:
                "xué",
              hanzi: "学",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin:
                "nèiróng",
              hanzi: "内容",
              symbol: "",
              group: 0,
              index: 7,
              symbolType: 0,
            },
            {
              pinyin:
                "jiějué",
              hanzi: "解决",
              symbol: "",
              group: 0,
              index: 8,
              symbolType: 0,
            },
            {
              pinyin:
                "wèntí",
              hanzi: "问题",
              symbol: "",
              group: 0,
              index: 9,
              symbolType: 0,
            },
            ],
          ],
        },
        {
          title: "读",
          isUse: false,
          content: [
            {
              pinyin:
                "duǎn jù",
              hanzi: "短句",
              symbol: "",
              group: 1,
              line: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin:
                "yuèdú",
              hanzi: "阅读",
              symbol: "",
              group: 1,
              line: 1,
              index: 2,
              symbolType: 0,
            },
            {
                pinyin: "",
                hanzi: "",
                symbol: ",",
                group: 0,
                line: 0,
                index: 3,
                symbolType: 1,
              },
            {
              pinyin:
                "duì",
              hanzi: "对",
              symbol: "",
              group: 1,
              line: 1,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin:
                "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 1,
              line: 1,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin:
                "cíjù",
              hanzi: "词句",
              symbol: "",
              group: 1,
              line: 1,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin:
                "jí",
              hanzi: "及",
              symbol: "",
              group: 1,
              line: 1,
              index: 7,
              symbolType: 0,
            },
            {
              pinyin:
                "pīnyīn",
              hanzi: "拼音",
              symbol: "",
              group: 1,
              line: 1,
              index: 8,
              symbolType: 0,
            },
            {
              pinyin:
                "kāizhǎn",
              hanzi: "开展",
              symbol: "",
              group: 1,
              line: 1,
              index: 9,
              symbolType: 0,
            },
            {
              pinyin:
                "zònghé",
              hanzi: "综合",
              symbol: "",
              group: 1,
              line: 1,
              index: 10,
              symbolType: 0,
            },
            {
              pinyin: "qùwèi",
              hanzi: "趣味",
              symbol: "",
              group: 1,
              line: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "liànxí",
              hanzi: "练习",
              symbol: "",
              group: 1,
              line: 1,
              index: 12,
              symbolType: 0,
            },
          ],
        },
        {
          title: "写",
          isUse: false,
          content: [
            {
              pinyin: "Jīběn",
              hanzi: "基本",
              symbol: "",
              group: 1,
              line: 1,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "bǐhuà",
              hanzi: "笔画",
              symbol: "",
              group: 1,
              line: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "jí",
              hanzi: "及",
              symbol: "",
              group: 1,
              line: 1,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin:
                "Hànzì",
              hanzi: "汉字",
              symbol: "",
              group: 1,
              line: 1,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin:
                "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 1,
              line: 1,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin:
                "jìnxíng",
              hanzi: "进行",
              symbol: "",
              group: 1,
              line: 1,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin:
                "zònghé",
              hanzi: "综合",
              symbol: "",
              group: 1,
              line: 1,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin:
                "liànxí",
              hanzi: "练习",
              symbol: "",
              group: 1,
              line: 1,
              index: 7,
              symbolType: 0,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>