<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "tā",
          hanzi: "他",
          english: "he",
          image: require(`@/assets/img/12-Vocabulary/lesson-17-ta-he-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "tā",
          hanzi: "她",
          english: "she",
          image: require(`@/assets/img/12-Vocabulary/lesson-17-ta-she-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "Zhōngguó rén",
          hanzi: "中国人",
          english: "Chinese (person; people)",
          image: require(`@/assets/img/12-Vocabulary/lesson-19-zhongguoren-bubble.svg`),
          longtext: true,
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "shénme",
          hanzi: "什么",
          english: "what",
          image: require(`@/assets/img/12-Vocabulary/lesson-19-shenme-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "yě",
          hanzi: "也",
          english: "also, too",
          image: require(`@/assets/img/12-Vocabulary/lesson-21-ye-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "six",
          isShowImg: "true",
          currentNum: 6,
          pinyin: "duō dà",
          hanzi: "多大",
          english: "how old",
          image: require(`@/assets/img/12-Vocabulary/lesson-21-duoda-bubble.svg`),
        },
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
