<template>
  <div class="game-container">
    <WriteHanziReviewPage
      :LeftImgList="LeftImgList"
      :hanziInfoList="hanziInfoList"
      :gifList="gifList"
    />
  </div>
</template>

<script>
import WriteHanziReviewPage from "@/components/Course/CoursePage/WriteHanziReviewPage";
export default {
  data() {
    return {
      hanziInfoList: [
        {
          pinyin: "tā",
          tipsList: ["他", "是", "我", "弟", "弟", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-18/hanzi-ta1-grey-border.svg"),
          stepNumber: 6,
          tipsIndex: 0,
        },
        {
          pinyin: "shén",
          tipsList: ["她", "叫", "什", "么", "？"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-20/hanzi-shen-grey-border.svg"),
          stepNumber: 5,
          tipsIndex: 2,
        },
        {
          pinyin: "dà",
          tipsList: ["你", "多", "大", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-22/hanzi-da-grey-border.svg"),
          stepNumber: 4,
          tipsIndex: 2,
        },
      ],
    };
  },
  computed: {
    LeftImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-24/image-${i}.svg`)
        );
      }
      return imgArr;
    },
    gifList() {
      let imgArrRen = [];
      for (let i = 1; i <= 5; i++) {
        imgArrRen.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-18/lesson-18-ta-he-${i}.gif`)
        );
      }
      let imgArrMa = [];
      for (let i = 1; i <= 4; i++) {
        imgArrMa.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-20/lesson-20-shen-${i}.gif`)
        );
      }
      let imgArrYou = [];
      for (let i = 1; i <= 3; i++) {
        imgArrYou.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-22/lesson-22-da-${i}.gif`)
        );
      }

      return [imgArrRen, imgArrMa, imgArrYou];
    },
  },
  components: {
    WriteHanziReviewPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
