<template>
  <div class="game-container">
    <RainDropGame
      :optionsList="optionsList"
      :answerList="answerList"
      :bgImage="bgImage"
      @changeAnswerStatus="changeAnswerStatus"
      :starNum="3"
    />
  </div>
</template>

<script>
import RainDropGame from "@/components/Course/GamePage/RainDropGame";
export default {
  data() {
    return {
      bgImage: require("@/assets/img/16-GAMES/G53-drops/background.svg"),
      optionsList: [
        [
          {
            index: 1,
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ta-she.svg"),
            isAnswer: true,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-da.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-shen.svg"),
          },
        ],
        [
         
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-sui.svg"),
            
          },
           {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-me.svg"),
            isAnswer: true,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ta-she.svg"),
          },
        ],
        [
        
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-da.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ta-she.svg"),
            
          },
            {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-sui.svg"),
            isAnswer: true,
            index: 3,
          },
        ],
      ],

      answerList: [
        [
          {
            id:1,
            hanzi: "她",
            pinying: "tā",
            isChooseCorrect: false,
          },
          {

            hanzi: "是",
            pinying: "shì",
            isChooseCorrect: true,
          },
          {
            hanzi: "谁",
            pinying: "shéi",
            isChooseCorrect: true,
          },
        ],
        [
          {
            hanzi: "什",
            pinying: "shén",
            isChooseCorrect: true,
          },
          {
            id: 2,
            hanzi: "么",
            pinying: "me",
            isChooseCorrect: false,
          },
          
        ],
        [
         
          {
            hanzi: "五",
            pinying: "wǔ",
            isChooseCorrect: true,
          },
          {
            id: 3,
            hanzi: "岁",
            pinying: "suì",
            isChooseCorrect: false,
          },
        ],
      ],
    };
  },
  components: {
    RainDropGame,
  },
  methods: {
    changeAnswerStatus(item, index) {
      this.answerList[index].forEach((ele) => {
        if (ele.id === item.index) {
          ele.isChooseCorrect = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











