<!--
 * @Author: shmily
 * @Date: 2022-04-07 10:26:41
 * @LastEditTime: 2022-04-07 10:26:41
 * @LastEditors: shmily
 * @Description: 
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter1\Lesson8\17_ReviewFour copy.vue
-->
<template>
  <div class="game-container">
    <ReviewTrain
      :step="0"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
      vocabularyReviewList: [
        [
          {
            pinyin: "tā",
            hanzi: "他",
          },
          {
            pinyin: "tā",
            hanzi: "她",
          },
          {
            pinyin: "shéi",
            hanzi: "谁",
          },
          {
            pinyin: "jiào",
            hanzi: "叫",
          },
          
        ],
        [
          
          {
            pinyin: "shénme",
            hanzi: "什么",
          },
          {
            pinyin: "jǐ",
            hanzi: "几",
          },
          {
            pinyin: "suì",
            hanzi: "岁",
          },
          {
            pinyin: "duō dà",
            hanzi: "多大",
          },
        ],
        [
          {
            pinyin: "Zhōngguó rén",
            hanzi: "中国人",
          },
          {
            pinyin: "yě",
            hanzi: "也",
          },
         {
            pinyin: "nǎ",
            hanzi: "哪",
          },
          {
            pinyin: "guó",
            hanzi: "国",
          },
        ],
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "Tā shì shéi?",
            hanzi: "他/她是谁？",
          },
          {
            pinyin: "Shì nǎ guó rén?",
            hanzi: "……是哪国人？",
          },
          {
            pinyin: "Duō dà / jǐ suì?",
            hanzi: "……多大/几岁？",
          },
        ],
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "d",
          },
          {
            vocabulary: "t",
          },
        ],
        [
            {
            vocabulary: "n",
          },
          {
            vocabulary: "l",
          },
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-18-1/lesson-18-shuwangou-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-20-1/lesson-20-piezhe-white.svg"),
          },
          
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-22-1/lesson-22-shuzhe-white.svg"),
          },
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>