<template>
  <div class="wordscard-container layout-border">
    <WordImgCards
      :wordCardList="wordCardList"
      :titleInfo="titleInfo"
      @changeTheImg="changeTheImg"
    />
  </div>
</template>
<script>
import WordImgCards from "@/components/Course/CoursePage/WordImgCards";

export default {
  components: {
    WordImgCards,
  },
  data() {
    return {
      titleInfo:{
        hanzi:"他/她是谁?",
        pinyin:"Tā/tā shì shéi?"
      },
      wordCardList: [
        {
          pinyin: "Tā shì Tútu.",
          hanzi: "他是图图。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-tutu.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-tutu-hidden.svg`),
          Showing:true,
        },
        {
          pinyin: "Tā shì Xiàxia.",
          hanzi: "她是夏夏。",
           image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-meimei.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-meimei-hidden.svg`),
          Showing:true,
        },
        {
          pinyin: "Tā shì Xiǎo'ài.",
          hanzi: "她是小爱。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaoai.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaoai-hidden.svg`),
          Showing:true,
        },
        {
          pinyin: "Tā shì Dōngdōng.",
          hanzi: "他是冬冬。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-dongdong.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-dongdong-hidden.svg`),
          Showing:true,
        },
        {
          pinyin: "Tā shì Xiǎotiān.",
          hanzi: "他是小天。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaotian.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-24/image-xiaotian-hidden.svg`),
          Showing:true,
        },
      ],
    };
  },
  methods: {
    changeTheImg(index) {
      this.wordCardList[index].Showing = !this.wordCardList[index].Showing
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
