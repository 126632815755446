<template>
  <div class="game-container">
    <DragToDressGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="24"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import DragToDressGame from "@/components/Course/GamePage/DragToDressGame";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "……几岁/多大？",
        pinyin: "…… jǐ suì / duō dà?",
      },
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/background-table.svg"),
      bgImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/2-done.svg"),
          id: 1,
          index:1,
          pinyin: "Xiàxia sì suì",
          hanzi: "夏夏四岁。",
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/1-done.svg"),
          id: 2,
          index:2,
          pinyin: "Dōngdōng liù suì",
          hanzi: "冬冬六岁。",
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/3-done.svg"),
          id: 2,
          index:3,
          pinyin: "Tútu liù suì",
          hanzi: "图图六岁。",
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/5-done.svg"),
          id: 4,
          index:4,
          pinyin: "Xiǎotiān sān suì",
          hanzi: "小天三岁。",
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/4-done.svg"),
          id: 2,
          index:5,
          pinyin: "Xiǎo'ài liù suì",
          hanzi: "小爱六岁。",
        },
      ],
      optionImgList: [
        {
          id: 1,
          index:1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/2.svg"),
        },
        {
          id: 2,
          index:2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/1.svg"),
        },
        {
          id: 2,
          index:3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/3.svg"),
        },
        {
          id: 4,
          index:4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/5.svg"),
        },
        {
          id: 2,
          index:5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-24/4.svg"),
        },
      ],
    };
  },
  components: {
    DragToDressGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











